import { render, staticRenderFns } from "./adminDetails.vue?vue&type=template&id=4281a76b&scoped=true"
import script from "./adminDetails.vue?vue&type=script&lang=js"
export * from "./adminDetails.vue?vue&type=script&lang=js"
import style0 from "./adminDetails.vue?vue&type=style&index=0&id=4281a76b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4281a76b",
  null
  
)

export default component.exports